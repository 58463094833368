import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ComboBase } from 'src/app/models/comboModel';

@Injectable({
    providedIn: 'root'
})
export class AtividadeGestorService {

    private environmentUrl: string = '';

    constructor(private httpClient: HttpClient) {
        this.environmentUrl = environment.ApiAtv;
    }
   
    getListaGestorCombo(): Observable<ComboBase[]> {
        let url = this.environmentUrl + "v1/Usuarios/Gestores";
        return this.httpClient.get<ComboBase[]>(url);
    }
}