<p-multiSelect 
    [options]="list" 
    [(ngModel)]="selectedItem" 
    defaultLabel="Selecione o Executor" 
    optionLabel="text"
    (ngModelChange)="onSelectionChange($event)"    
    selectedItemsLabel="{0} Item(ns) Selecionado(s)"
    [style]="{'width':'100%'}"
    [maxSelectedLabels]="3"
    [panelStyle]="{'max-width': '100%'}">
</p-multiSelect>
