export const environment = {
  production: true,
  enableLogs: false,

  ApiUrl: 'https://convenioapi.azurewebsites.net/api/',
  apiAuth: 'https://convenioapi.azurewebsites.net/api/',
  ApiCore:'https://convenioapi.azurewebsites.net/api/',
  ApiAtv:'https://convenioapi.azurewebsites.net/api/',

  
  env: 'PRD',
};
