import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AtividadeGestorService } from 'src/app/core/services/atividade-gestor.service';
import { ComboBase } from 'src/app/models/comboModel';

@Component({
  selector: 'sgc-atividade-gestor-multiselect',
  templateUrl: './atividade-gestor-multiselect.component.html',
  styleUrls: ['./atividade-gestor-multiselect.component.scss']
})
export class AtividadeGestorMultiselectComponent implements OnInit, OnDestroy {

  list!: ComboBase[];
  @Input() selectedItem!: ComboBase[];
  @Output() itemSelecionado = new EventEmitter();

  constructor(private atividadeGestorService: AtividadeGestorService
    
  ) { }
   
  ngOnInit(): void {
    this.getLista();
       
  }

  ngOnDestroy(): void {
  }

  getLista() {
    this.atividadeGestorService.getListaGestorCombo()
      .subscribe(
        (res: any) => {
          if (res) {
            for (let i = 0; i < res.length; i++) {
              res[i].value = res[i].gestorId;  
              res[i].text = res[i].gestorId + ' - ' + res[i].nomeGestor;
              res[i].code =  res[i].etapaIds;              
            }
            this.list = res; 
          }
        },
        (err: any) => {
          console.log(`AtividadeGestorMultiSelect - getLista ` + err);
        }
      );
  }

  onChange(event: any) {
    if (event.value !== null && event.value !== undefined) {
      this.itemSelecionado.emit(event.value);
    }
  }
}



