import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ComboBase } from 'src/app/models/comboModel';

@Injectable({
    providedIn: 'root'
})
export class AtividadeExecutorService {

    private environmentUrl: string = '';

    constructor(private httpClient: HttpClient) {
        this.environmentUrl = environment.ApiAtv;
    }
   
    getListaExecutorCombo2(): Observable<ComboBase[]> {
        let url = this.environmentUrl + "v1/Usuarios/Executores";
       
        const body = {
            gestoresId: [],
            etapasId: []    
        }; 
  
        return this.httpClient.post<ComboBase[]>(url, body);      
    }

    getListaExecutorCombo(): Observable<ComboBase[]> {
        let url = this.environmentUrl + "v1/Usuarios/Executores";
        return this.httpClient.get<ComboBase[]>(url);
    }    
}