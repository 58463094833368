<p-multiSelect 
    [options]="list" 
    [(ngModel)]="selectedItem" 
    defaultLabel="Selecione o Gestor" 
    optionLabel="text"
    (onChange)="onChange($event)"
    selectedItemsLabel="{0} Item(ns) Selecionado(s)"
    [maxSelectedLabels]="3"
    [style]="{'width':'100%'}"
    [panelStyle]="{'max-width': '100%'}">
</p-multiSelect>
